@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Rubik/Rubik-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Rubik/Rubik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Malgun Gothic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Malgun-Gothic/Malgun-Gothic-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Malgun Gothic';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Malgun-Gothic/Malgun-Gothic-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Malgun Gothic';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Malgun-Gothic/Malgun-Gothic-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rubik', 'Malgun Gothic',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
